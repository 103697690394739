import axios, { AxiosRequestConfig } from "axios";
import { AttributeColors, AttributeColorsResponse } from "@shared/types";
import { GLOBAL_CONFIG } from "@shared/constants";

/**
 * Fetch y00ts attribute colors.
 *
 * @returns Object containing rarity tags and each attribute's corresponding colors
 */

export const fetchAttributeColors = async () => {
  let config: AxiosRequestConfig = {
    method: "get",
    url: `${GLOBAL_CONFIG.API_URL}/y00ts/traitHex`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios(config)
    .then((response) => {
      const data = response.data as AttributeColorsResponse;
      return data.rarityTags as AttributeColors;
    })
    .catch((error) => {
      console.error("staked error: ", error.message);
    });
};
