import { ImageLoaderProps } from "next/image";

const BASE_URL = typeof window !== "undefined" ? window.location.origin : "http://localhost:3000";

const normalizeSrc: (src: string) => string | number = (src: string) => {
  return src[0] === "/" ? BASE_URL + src : src;
};

const cloudflareLoader: (params: ImageLoaderProps) => string = ({
  src,
  width,
  quality = 100,
}: ImageLoaderProps) => {
  const params = [`width=${width}`];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join("&");
  return `https://image-resizing.degods.workers.dev?${paramsString}&image=${normalizeSrc(
    src
  )}`;
};

export default cloudflareLoader;