export const degodsAttributeTraitMap = new Map([
  // Attributes
  ["background", "Background"],
  ["clothes", "Clothes"],
  ["eyes", "Eyes"],
  ["face", "Face"],
  ["neck", "Neck"],
  ["skin", "Skin"],
  ["head", "Head"],
  ["mouth", "Mouth"],
  ["specialty", "Specialty"],
  ["version", "Version"],
  ["y00t", "y00t"],
  ["all", "All"],

  // Eyes
  ["none", "None"],
  ["eye_patch", "Eye Patch"],
  ["red_wine_glasses", "Red Wine Glasses"],
  ["clout_goggles", "Clout Goggles"],
  ["all_seeing_eyes", "All Seeing Eyes"],
  ["rugrat_frames", "Rugrat Frames"],
  ["snake_eyes", "Snake Eyes"],
  ["demonacle", "Demonacle"],
  ["round_glasses", "Round Glasses"],
  ["alien_spectacles", "Alien Spectacles"],
  ["swirly_glasses", "Swirly Glasses"],
  ["night_vision_goggles", "Night Vision Goggles"],
  ["neon_shades", "Neon Shades"],
  ["rose_tinted_shades", "Rose Tinted Shades"],
  ["steampunk_goggles", "Steampunk Goggles"],
  ["glowing_eyes", "Glowing Eyes"],
  ["money_sign_shades", "Money Sign Shades"],
  ["playboy_bunny_glasses", "Playboy Bunny Glasses"],
  ["laser_eyes", "Laser Eyes"],
  ["degucci_goggles", "DeGucci Goggles"],
  ["rainbow_laser_eyes", "Rainbow Laser Eyes"],

  // Head
  ["degod_pro_trucker", "DeGod Pro Trucker"],
  ["prince_hair", "Prince Hair"],
  ["fade", "Fade"],
  ["headband", "Headband"],
  ["hippie_hair", "Hippie Hair"],
  ["mad_scientist", "Mad Scientist"],
  ["leaf_laurel", "Leaf Laurel"],
  ["bleached_curls", "Bleached Curls"],
  ["ushanka", "Ushanka"],
  ["curly", "Curly"],
  ["skull_mask", "Skull Mask"],
  ["mushroom_head", "Mushroom Head"],
  ["god_dome", "God Dome"],
  ["gold_leaf_laurel", "Gold Leaf Laurel"],
  ["mullet", "Mullet"],
  ["bed_head", "Bed Head"],
  ["godfro", "Godfro"],
  ["godlielocks", "Godlielocks"],
  ["bleached", "Bleached"],
  ["godhawk", "Godhawk"],
  ["propeller_hat", "Propeller Hat"],
  ["babushka", "Babushka"],
  ["plank", "Plank"],
  ["exposed_brain", "Exposed Brain"],
  ["winged_helmet", "Winged Helmet"],
  ["bloody_horns", "Bloody Horns"],
  ["rainbow_definitely_not_a_dildo", "Rainbow Definitely Not A Dildo"],
  ["degucci_bucket_hat", "DeGucci Bucket Hat"],
  ["pharaohs_headdress", "Pharaohs Headdress"],
  ["mickey_degods_visor", "Mickey DeGods Visor"],
  ["dimmadome_top_hat", "Dimmadome Top Hat"],
  ["heavenly_crown", "Heavenly Crown"],
  ["mythic_halo", "Mythic Halo"],
  ["medusa", "Medusa"],
  ["mythic_helmet", "Mythic Helmet"],

  // Neck
  ["none", "None"],
  ["gold_chain", "Gold Chain"],
  ["diamond_necklace", "Diamond Necklace"],
  ["louis_scarf", "Louis Scarf"],
  ["silver_chain", "Silver Chain"],
  ["friendship_necklace", "Friendship Necklace"],
  ["pimp_chain", "Pimp Chain"],
  ["christmas_lights_chain", "Christmas Lights Chain"],
  ["paperclip_necklace", "Paperclip Necklace"],
  ["cannibal_necklace", "Cannibal Necklace"],
  ["double_diamond_necklace", "Double Diamond Necklace"],
  ["solana_chain", "Solana Chain"],
  ["mythic_mamba", "Mythic Mamba"],

  // Skin
  ["leopard", "Leopard"],
  ["solar_spiral", "Solar Spiral"],
  ["lavender_stripe", "Lavender Stripe"],
  ["marble", "Marble"],
  ["pink_granite", "Pink Granite"],
  ["blue_sky", "Blue Sky"],
  ["pastel", "Pastel"],
  ["turquoise", "Turquoise"],
  ["poker", "Poker"],
  ["zebra", "Zebra"],
  ["x-ray", "X-Ray"],
  ["navi", "Navi"],
  ["gold", "Gold"],
  ["zombie", "Zombie"],
  ["ape", "Ape"],
  ["joker", "Joker"],
  ["radioactive", "Radioactive"],
  ["transcendent", "Transcendent"],
  ["ice", "Ice"],
  ["acid", "Acid"],
  ["nebula", "Nebula"],

  // Mouth
  ["clown_nose", "Clown Nose"],
  ["none", "None"],
  ["porn_stashe", "Porn Stashe"],
  ["vape_god", "Vape God"],
  ["party_blower", "Party Blower"],
  ["hipster_beard", "Hipster Beard"],
  ["santa_beard", "Santa Beard"],
  ["goatee", "Goatee"],
  ["smoke_ring", "Smoke Ring"],
  ["cigarette", "Cigarette"],
  ["heavenly_wood", "Heavenly Wood"],
  ["fruit_roll_up", "Fruit Roll Up"],

  // Clothes
  ["degod_cutoff", "DeGod Cutoff"],
  ["war_armor", "War Armor"],
  ["tourist_tee", "Tourist Tee"],
  ["gym_tee", "Gym Tee"],
  ["caesar_tunic", "Caesar Tunic"],
  ["god_beater", "God Beater"],
  ["bleached_tee", "Bleached Tee"],
  ["steve_jobs_turtleneck", "Steve Jobs Turtleneck"],
  ["god_scout_uniform", "God Scout Uniform"],
  ["tattered_tee", "Tattered Tee"],
  ["jean_jacket", "Jean Jacket"],
  ["tech_god_vest", "Tech God Vest"],
  ["godchella_hoodie", "Godchella Hoodie"],
  ["googod_tee", "Googod Tee"],
  ["druglord_suit", "Druglord Suit"],
  ["godly_tunic", "Godly Tunic"],
  ["peasant_blouse", "Peasant Blouse"],
  ["overalls", "Overalls"],
  ["tracksuit", "Tracksuit"],
  ["top_god_jumper", "Top God Jumper"],
  ["dedoctor_coat", "DeDoctor Coat"],
  ["none", "None"],
  ["silk_robe", "Silk Robe"],
  ["hawaiian_shirt", "Hawaiian Shirt"],
  ["hazmat_suit", "Hazmat Suit"],
  ["bloody_beater", "Bloody Beater"],
  ["radioactive_tee", "Radioactive Tee"],
  ["god_jersey", "God Jersey"],
  ["world_champion_robe", "World Champion Robe"],
  ["mickey_degods_uniform", "Mickey DeGods Uniform"],
  ["ftx_jersey", "FTX Jersey"],
  ["mythic_war_armor", "Mythic War Armor"],
  ["divine_robe", "Divine Robe"],

  // Specialty
  ["none", "None"],
  ["archer", "Archer"],
  ["black_eye", "Black Eye"],
  ["quarterback", "Quarterback"],
  ["rockstar_tats", "Rockstar Tats"],
  ["forehead_diamond", "Forehead Diamond"],
  ["god_of_war", "God of War"],
  ["devil_wings", "Devil Wings"],
  ["earring", "Earring"],
  ["angel_wings", "Angel Wings"],
  ["third_eye", "Third Eye"],
  ["mythic_wings", "Mythic Wings"],

  // Background
  ["purple", "Purple"],
  ["red", "Red"],
  ["olive_green", "Olive Green"],
  ["brown", "Brown"],
  ["blue", "Blue"],
  ["teal", "Teal"],
  ["deep_ocean_blue", "Deep Ocean Blue"],
  ["supernova", "Supernova"],
  ["hell", "Hell"],
  ["alaska", "Alaska"],
  ["heaven", "Heaven"],

  // version
  //   ["degod", "DeGod"],
  //   ["deadgod", "DeadGod"],

  // y00t
  // ["claimed", "Claimed"],
  ["unclaimed", "Unclaimed"],
]);
