import axios from "axios";
import { GLOBAL_CONFIG } from "@shared/constants";
import { signMessage } from "@wagmi/core";

/**
 * Fetch Solana transaction hash
 *
 * @param publicKey Public key of user as string
 *
 * @returns transaction hash string for user to sign.
 */

export const fetchTransactionHash = async (publicKey: string) => {
  return await axios
    .get(`${GLOBAL_CONFIG.API_URL}/users/${publicKey}/transaction/`)
    .then((res) => {
      return res.data.transactionHash as string;
    })
    .catch((err) => {
      console.error("Error getting transaction hash ", err.message);
    });
};

/**
 * Fetch EVM nonce signature
 *
 * @param publicKey Public key of user as string
 *
 * @returns nonce signature as string.
 */

export const fetchSignatureFromNonce = async (publicKey: string) => {
  return await axios
    .get(`${GLOBAL_CONFIG.API_URL}/nonce/${publicKey}`)
    .then(async (res) => {
      const nonce = res.data.nonce as string;
      // sign the message
      const signature = await signMessage({ message: nonce });

      return signature;
    })
    .catch((err) => {
      console.error("Error getting nonce ", err.message);
      return null;
    });
};
